import React, { useCallback, useMemo } from 'react'
import { useGetRadioHost } from '@api/queries/radio/hosts'
import { TypeRadioHostFormValues } from '@customTypes/radio'
import { Form, Formik } from 'formik'
import { radioHostValidationSchema } from '@containers/radio/utils'
import { RadioHostFormEnum, RadioHostRoleEnum } from '@enums/radio'
import UpdateHostInner from '@containers/radio/pages/UpdateHostInner'
import Loader from '@components/loader/Loader'

interface Props {
  radioId: string
  onSubmit: () => void
  selectedHostId: string | null
  onClickViewPosts: () => void
}

const UpdateHost: React.FC<Props> = ({
  radioId,
  onSubmit,
  selectedHostId,
  onClickViewPosts,
}) => {
  const { data: radioHost, isLoading } = useGetRadioHost(selectedHostId || '', {
    enabled: Boolean(selectedHostId),
  })

  const handleSubmit = useCallback((formValues: TypeRadioHostFormValues) => {
    console.log(formValues)
  }, [])

  const initialValues = useMemo<TypeRadioHostFormValues>(() => {
    return {
      [RadioHostFormEnum.Name]: radioHost?.name || '',
      [RadioHostFormEnum.Bio]: radioHost?.bio || '',
      [RadioHostFormEnum.Role]: RadioHostRoleEnum.Host,
      [RadioHostFormEnum.ProfileImage]: radioHost?.profileImage || '',
      [RadioHostFormEnum.EnglishName]: radioHost?.englishName || '',
      [RadioHostFormEnum.WelcomeMessage]: radioHost?.welcomeMessage || '',
      [RadioHostFormEnum.PreviewImage]: radioHost?.previewImage || '',
      [RadioHostFormEnum.MostRelevantCategoryIds]:
        radioHost?.mostRelevantCategoryIds || [],
      [RadioHostFormEnum.AdditionalCategoryIds]:
        radioHost?.additionalCategoryIds || [],
      [RadioHostFormEnum.IsVisibleToConsumers]:
        radioHost?.isVisibleToConsumers || false,
    }
  }, [radioHost])

  if (isLoading) {
    return <Loader visible={isLoading} fullWidth />
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={radioHostValidationSchema}
      onSubmit={handleSubmit}
      enableReinitialize
    >
      <Form className={'w-full'}>
        <UpdateHostInner
          onSubmit={onSubmit}
          selectedHost={radioHost}
          radioId={radioId}
          onClickViewPosts={onClickViewPosts}
        />
      </Form>
    </Formik>
  )
}

export default UpdateHost
