import React, { useCallback, useEffect, useMemo, useState } from 'react'
import {
  useGetRadioMessagesByCategory,
  useGetRadioMessagesByHost,
} from '@api/queries/radio/messages'
import { telegramService } from '@services/telegram/telegramService'
import { _t } from '@locales/index'
import Post from '@containers/radio/components/Post'
import { TypeRadioMessage, TypeRadioMessagesResponse } from '@customTypes/radio'
import moment from 'moment/moment'
import ChooseRadioData from '@containers/radio/ChooseRadioData'

interface Props {
  categoryId?: number | null
  hostId?: string | null
  onClickBack: () => void
}

const Posts: React.FC<Props> = ({ categoryId, hostId, onClickBack }) => {
  const [selectedBotSchedulerId, setSelectedBotSchedulerId] = useState<number>()

  const { data: radioMessagesByCategory } = useGetRadioMessagesByCategory(
    categoryId?.toString() || '',
    {
      enabled: Boolean(categoryId),
    },
  )

  const { data: radioMessagesByHost } = useGetRadioMessagesByHost(
    hostId || '',
    {
      enabled: Boolean(hostId),
    },
  )

  const handleClickEdit = useCallback((botSchedulerId: number) => {
    setSelectedBotSchedulerId(botSchedulerId)
  }, [])

  useEffect(() => {
    telegramService.mainButton.hide()
    telegramService.backButton.show().onClick(onClickBack)
    return () => {
      telegramService.backButton.hide().offClick(onClickBack)
    }
  }, [onClickBack])

  const radioMessagesResponse = useMemo<
    TypeRadioMessagesResponse | undefined
  >(() => {
    return radioMessagesByCategory || radioMessagesByHost
  }, [radioMessagesByCategory, radioMessagesByHost])

  const sortedRadioMessages = useMemo<TypeRadioMessage[] | null>(() => {
    if (
      !radioMessagesResponse?.radioMessages ||
      radioMessagesResponse.radioMessages.length === 0
    ) {
      return null
    }
    return radioMessagesResponse.radioMessages.sort((a, b) => {
      const momentA = moment(a.botScheduler.nextPostDate)
      const momentB = moment(b.botScheduler.nextPostDate)
      return momentA.diff(momentB)
    })
  }, [radioMessagesResponse?.radioMessages])

  if (selectedBotSchedulerId) {
    return (
      <ChooseRadioData
        botSchedulerId={selectedBotSchedulerId}
        onClickBack={() => setSelectedBotSchedulerId(undefined)}
      />
    )
  }

  if (!sortedRadioMessages) {
    return <p className='text-center'>{_t('noPostsForCategory')}</p>
  }

  return (
    <div className='flex flex-col gap-5 items-stretch secondary-bg p-5 h-full'>
      {sortedRadioMessages.map(radioMessage => (
        <Post
          key={radioMessage.id}
          radioMessage={radioMessage}
          showEdit={!radioMessage.sendOnce}
          onClickEdit={handleClickEdit}
        />
      ))}
    </div>
  )
}

export default Posts
